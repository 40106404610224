$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 150; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");

        // hide profile popup
        if (window.innerWidth <= 1000) {
            $(".js-tab-item").removeClass("active");
            $(".js-tab-card-item").removeClass("active");
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider");

    if ($heroSlider.length) {
        $heroSlider.each(function () {

            $(this).find(".js-hero-slider-track").randomize().slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 2000,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-hero-slider-dots"),
                prevArrow: $(this).find(".js-hero-slider-prev"),
                nextArrow: $(this).find(".js-hero-slider-next"),
            });


            $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $(this).find(".js-hero-slider-count").text(i);
            });


        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentSlider
    // *
    // *
    var $contentSlider = $(".js-content-slider");

    if ($contentSlider.length) {
        $contentSlider.each(function () {
            $(this).find(".js-content-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-content-slider-dots"),
                prevArrow: $(this).find(".js-content-slider-prev"),
                nextArrow: $(this).find(".js-content-slider-next"),
            });

            $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $(this).find(".js-content-slider-count").text(i);
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * quoteSlider
    // *
    // *
    var $quoteSlider = $(".js-quote-slider");

    if ($quoteSlider.length) {
        $quoteSlider.each(function () {
            $(this).find(".js-quote-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-quote-slider-dots"),
                prevArrow: $(this).find(".js-quote-slider-prev"),
                nextArrow: $(this).find(".js-quote-slider-next"),
            });

            $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $(this).find(".js-quote-slider-count").text(i);
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * textimage
    // *
    // *
    var $textimageSlider = $(".js-textimage-slider");

    if ($textimageSlider.length) {
        $textimageSlider.each(function () {
            $(this).find(".js-textimage-slider-track").slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-textimage-slider-dots"),
                prevArrow: $(this).find(".js-textimage-slider-prev"),
                nextArrow: $(this).find(".js-textimage-slider-next"),
            });

            $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
                var i = (currentSlide ? currentSlide : 0) + 1;
                $(this).find(".js-textimage-slider-count").text(i);
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tab
    // *
    // *
    $(".js-tab-item").click(function () {
        var tabId = $(this).data("name");

        // hide siblings
        $(".js-tab-item").removeClass("active");
        $(".js-tab-card-item").removeClass("active");

        // show clicked
        $(this).addClass("active");
        $('#' + tabId).addClass("active");
    });

    $(".js-tab-close").click(function () {
        $(".js-tab-item").removeClass("active");
        $(".js-tab-card-item").removeClass("active");
    });


    // Open tab with anchor-link
    let urlHash = window.location.hash.substring(1);
    if (urlHash) {
        const targetElement = $(`[data-jump="${urlHash}"]`);

        if (targetElement.length) {
            targetElement.trigger('click');

            const tabWrapper = targetElement.closest('.js-tab-wrapper');

            if (tabWrapper.length) {
                $('html, body').animate({
                    scrollTop: tabWrapper.offset().top - 50
                }, 200); // Adjust the duration as needed
            }
        }
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video
    // *
    // *
    $(".js-fancybox").fancybox({
        type: "iframe",
        // other API options
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-header").click(function () {
        $(this).parents(".js-accordion-item").toggleClass("active").siblings(".js-accordion-item").removeClass("active");
        $(this).parents(".js-accordion-item").find(".js-accordion-content").slideToggle();

        // $(this).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
        // $(this).siblings(".js-accordion-content").slideToggle();
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    var $dialog = $(".js-dialog"),
        dialogState = sessionStorage.getItem('dialogState');

    $(".js-close-dialog").click(function () {
        $dialog.removeClass("open");
        $dialog.addClass("close");
        sessionStorage.setItem('dialogState', true);
        $("body").removeClass("freeze");
    });

    // backdrop click
    $(".js-dialog").click(function (e) {
        if (e.target === e.currentTarget) {
            $dialog.removeClass("open");
            $dialog.addClass("close");
            $("body").removeClass("freeze");
        }
    });

    if (!dialogState && $dialog.length > 0) {
        $dialog.addClass("open");
        $("body").addClass("freeze");
    }

    // Handle radio button toggle
    $(document).on("keydown click", ".js-form-checkbox", function (event) {
        if (event.type === "click" || event.code === "Space" || event.key === " ") {
            event.preventDefault(); // Prevent default action (e.g., scrolling for Space key)

            const $input = $(this).siblings(".js-checkbox-input");

            if ($input.length && $input.attr("type") === "radio") {
                // Check the clicked radio
                $input.prop("checked", true).attr("aria-checked", "true");

                // Uncheck other radios in the group
                const name = $input.attr("name");
                $(`input[name="${name}"]`).not($input).prop("checked", false).attr("aria-checked", "false");

                // Trigger the change event for additional logic
                $input.trigger("change");
            }
        }
    });

    // Handle checkbox toggle
    $(document).on("keydown click", ".js-form-checkbox", function (event) {
        if (event.type === "click" || event.code === "Space" || event.key === " ") {
            event.preventDefault(); // Prevent default action (e.g., scrolling for Space key)

            const $input = $(this).siblings(".js-checkbox-input");

            if ($input.length && $input.attr("type") === "checkbox") {
                // Toggle checkbox state
                const isChecked = $input.prop("checked");
                $input.prop("checked", !isChecked);

                // Optionally update aria-checked attribute
                const $label = $(this).closest(".form__checkbox");
                $label.attr("aria-checked", !isChecked);

                // Trigger the change event for additional logic
                $input.trigger("change");
            }
        }
    });
  


});

$.fn.randomize = function (selector) {
    var $elems = selector ? $(this).find(selector) : $(this).children(),
        $parents = $elems.parent();

    $parents.each(function () {
        $(this).children(selector).sort(function (childA, childB) {
            // * Prevent last slide from being reordered
            if ($(childB).index() !== $(this).children(selector).length - 1) {
                return Math.round(Math.random()) - 0.5;
            }
        }.bind(this)).detach().appendTo(this);
    });

    return this;


};

// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});

const $timeline = $('.js-timeline');
if ($timeline.length > 0) {

    $(window).on('scroll', function () {
        // Get the element's position relative to the viewport
        const rect = $timeline.get(0).getBoundingClientRect();

        // Calculate the window height and define offset from the bottom
        const windowHeight = $(window).height();
        const offset = 50; // 50px offset from the bottom

        // Calculate the scroll progress
        const progress = Math.min(
            100,
            ((windowHeight - rect.top - offset) / rect.height) * 100
        );

        // Set the CSS variable to control the height of the element
        $timeline.css('--timeline-progress', `${progress}%`);
    });
}

$(window).on('load resize', function () {
    setTimeout(() => {
        if (window.innerWidth > 1000) {
            $(".js-tab-item").first().trigger("click");
        }
        else {
            $(".js-tab-close").first().trigger("click");
        }
    }, 100);
   
});
